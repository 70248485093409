import React from 'react';
import { Box } from '@chakra-ui/core';
import PropTypes from 'prop-types';
import BlogHeader from './BlogHeader';
import Footer from './Footer';

const Layout = (props) => (
  <>
    <>
      <BlogHeader />
    </>
    <section className="min-h-screen block">
      <Box
        mt="74px"
        className="site-content">
        {props.children}
      </Box>
    </section>
    <>
      {props.prependInFooter}
      <Footer />
    </>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
