import React from "react";
import { Highlight } from "react-instantsearch-dom";

const SearchResultItem = ({ hit }) => {
    return (
        <a href={`${hit.uri}`} class="block">
            <div class="flex px-2 md:px-3 py-2 bg-white hover:bg-gray-100 transition duration-300 items-center" >
                <div className="relative bg-gray-200 overflow-hidden mr-2 h-20 w-28 flex-grow-0">
                    <img src={hit.imgSrc} style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}} alt={hit.title} />
                </div>
                <span className="flex-1 text-sm"><Highlight tagName="mark" attribute="title" hit={hit} /></span>
            </div>
        </a>
    );
    
}
export default SearchResultItem;