import React from "react"

import Search from './Search';
import MakerButton from './MakerButton';

import useSiteMetadata from '../hooks/use-site-metadata';
import logo from "../../../images/site-logo-onwhite.png";

export default function BlogHeader() {
  const { companyUrl } = useSiteMetadata();

  return (
    <div className="fixed w-full bg-white top-0 z-40">
      <div className="max-w-7xl mx-auto w-full">
        <div className="flex items-center p-2 md:justify-start md:space-x-10 relative sm:relative w-full md:h-20">
          <div className="flex-grow">
            <a href="/">
              <img
                src={logo}
                alt="Maker"
                className="mkr-header-logo inline-block w-auto h-4 sm:h-4 md:h-6 align-middle"
              />
            </a>
          </div>
          <div className="flex-grow-1 align-right">
            <div className="flex space-x-none md:space-x-4 flex-row-reverse md:flex-row items-center">
              <div className="absolute md:relative z-50 right-0">
                <Search/>
              </div>
              <div className="relative mr-8">
                <MakerButton link={companyUrl} active={true} text="WHAT'S MAKER?" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}