import React, {useEffect} from "react"
import { Helmet } from "react-helmet-async"
import { useLocation } from "@reach/router";
import useSiteMetadata from '../hooks/use-site-metadata';
import defaultOGImage from '../../../images/default_og_image.jpg';
import "../theme/tailwind.output.css";
import "../theme/searchbar.css";
import '../theme/critical.css';

const SEO = ({ lang, additionalKeywords, featuredImage, titleText, descriptionText }) => {

    const {pathname} = useLocation();

    const { title, description, keywords, siteUrl, googleAnalyticsId, twitter } = useSiteMetadata();
    const metaDescription = descriptionText ? descriptionText : description;
    const metaKeywords = keywords + (!!additionalKeywords ? additionalKeywords : "");
    const metaTitle = ((titleText === "Home" && !!titleText) ? title : `${titleText} | ${title}`);
    const metaImage = siteUrl + (!!featuredImage ? featuredImage : defaultOGImage);
    const metaUrl = siteUrl + pathname;

    useEffect(() => {
        if (typeof window != "undefined") {
            window.scrollTo(0, 0);
        }
    }, []);

    const code = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${googleAnalyticsId}');`;

    return (
        <Helmet
        htmlAttributes={{
            lang,
        }}
        title={metaTitle} >
            {
                typeof window === "undefined" ? (<>
                    <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`} />
                    <script type="application/javascript" data-label="google-analytics">{code}</script>
                </>) : null
            }
            <meta name="keywords" content={metaKeywords}></meta>
            <meta name="description" content={metaDescription}></meta>

            <meta name="og:description" content={metaDescription}></meta>
            <meta name="og:url" content={metaUrl}></meta>
            <meta name="og:site_name" content={title}></meta>
            <meta name="og:title" content={metaTitle}></meta>
            <meta name="og:image" content={metaImage}></meta>
            <meta name="og:image:alt" content={metaTitle} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={metaTitle} />
            <meta name="twitter:description" content={metaDescription} />
            <meta name="twitter:image" content={metaImage} />
            <meta name="twitter:image:alt" content={metaTitle} />
            <meta name="twitter:handle" content={twitter} />
            <meta name="twitter:site" content={siteUrl} />
        </Helmet>
    )
}

SEO.defaultProps = {
  lang: `en`
}

export default SEO
