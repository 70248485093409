import React from 'react';
import {
  Box, Text, Link, Grid, VisuallyHidden
} from '@chakra-ui/core';

import useSiteMetadata from '../hooks/use-site-metadata';
import useCustomerMenu from "../hooks/use-customer-menu";

import logo from "../../../images/maker_logo_black.svg";

const Footer = () => {
  const { author, twitter, facebook, instagram, linkedin, companyUrl, copyrightYear } = useSiteMetadata();
  const customers = useCustomerMenu();

  const footerHeading = "font-semibold ml-1 line leading-normal text-base md:text-lg bottom-0";

  return (
    <Box
      as="footer"
      bg="footerBg"
      w="100%"
      pt={4}
      pb={2}
      className="site-footer">
      <Box
        display="block"
        maxW={["512px", "512px", "1024px"]}
        m="auto"
        overflow="hidden"
        color="muted"
        fontSize="sm"
        justifyContent="space-between" >
        <Box px={["1rem", null, null]} gridGap={["40px 10px", null, null]} display="grid" gridTemplateAreas={["'a a' '. .' '. .'", "'a a' '. .' '. .'", "'a . . .'"]} templateColumns={["repeat(2, 1fr)", "repeat(4, 1fr)", "repeat(4, 1fr)"]} gap={4}>
          <Box style={{gridAutoRows: "minmax(0, max-content)"}} display={["flex", "flex", "grid"]} alignItems="center" gridArea={["a", null, null]} templateColumns="repeat(1, 1fr)" spacing={2} color="footerText">
            <Box
              gridAutoRows="1fr"
              alignItems="baseline"
              flexGrow={5}
              position="relative"
              height="33px"
              pr={5}>
              <Link
                display="inline-block"
                w="100px"
                position="absolute"
                bottom={["auto", "auto", 0]}
                href={companyUrl}
                aria-label="Maker Inc.">
                <img width="100%" src={logo} alt="Maker Inc."/>
              </Link>
            </Box>
            <Box
              gridAutoRows="1fr"
              display="block"
              pt={[0, 0, "30px"]}
              alignItems="center"
              pr={[0, 0, "5px"]}
              mr={[1, 1, 0]}
              h={["34px", "34px", "auto"]}>
              <Link
                href={`https://facebook.com/${facebook}`}
                aria-label="Facebook"
                leftIcon="at-sign"
                variant="outline"
                outline="none"
                opacity="1"
                display={"inline-flex"}
                alignItems="center"
                position="relative"
              >
                <Box position={["static", "static", "absolute"]} w={["34px", "34px", "17px"]} h={["34px", "34px", "17px"]} p={["5px", "5px", 0]}>
                  <svg viewBox="0 0 17 17">
                      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                          <g id="Menu-/-Footer" transform="translate(-380.000000, -698.000000)" stroke="#242424" strokeWidth="1.5">
                              <g id="Group-45" transform="translate(378.000000, 624.000000)">
                                  <path d="M11.1230769,75 L8.90769231,75 C6.86848708,75 5.21538462,76.6531025 5.21538462,78.6923077 L5.21538462,80.9076923 L3,80.9076923 L3,83.8615385 L5.21538462,83.8615385 L5.21538462,89.7692308 L8.16923077,89.7692308 L8.16923077,83.8615385 L10.3846154,83.8615385 L11.1230769,80.9076923 L8.16923077,80.9076923 L8.16923077,78.6923077 C8.16923077,78.2844666 8.49985126,77.9538462 8.90769231,77.9538462 L11.1230769,77.9538462 L11.1230769,75 Z" id="Path"></path>
                              </g>
                          </g>
                      </g>
                  </svg>
                </Box>
                <Box as="span" ml="25px" display={["none", "none", "inline-block"]} verticalAlign="middle" className="text-sm">Facebook</Box>
              </Link>
            </Box>
            <Box
              gridAutoRows="1fr"
              display="block"
              pt={[0, 0, "19px"]}
              alignItems="center"
              pr={[0, 0, "5px"]}
              mr={[1, 1, 0]}
              h={["34px", "34px", "auto"]}>
              <Link
                href={`https://twitter.com/${twitter}`}
                aria-label="Twiiter"
                leftIcon="at-sign"
                variant="outline"
                outline="none"
                opacity="1"
                display={"inline-flex"}
                alignItems="center"
                position="relative"
              >
              <Box position={["static", "static", "absolute"]} w={["34px", "34px", "17px"]} h={["34px", "34px", "17px"]} p={["5px", "5px", 0]} display="inline-block" verticalAlign="middle">
                <svg viewBox="0 0 17 17" className="top-1 md:static relative right-1">
                  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                        <g id="Menu-/-Footer" transform="translate(-377.000000, -741.000000)" stroke="#242424" strokeWidth="1.5">
                            <g id="Group-45" transform="translate(378.000000, 624.000000)">
                                <path d="M14.7692308,118.006758 C14.1263564,118.451534 13.4145619,118.791717 12.6612587,119.014206 C11.8330805,118.080201 10.4984861,117.752666 9.3194393,118.19406 C8.14039248,118.635453 7.36769808,119.751882 7.38461538,120.989594 L7.38461538,121.648057 C4.98841834,121.708999 2.72024077,120.589229 1.34265734,118.66522 C1.34265734,118.66522 -1.34265734,124.591386 4.6993007,127.225238 C3.31671781,128.145747 1.66969974,128.607305 0,128.542163 C6.04195804,131.834478 13.4265734,128.542163 13.4265734,120.96984 C13.4259552,120.786428 13.4079759,120.603468 13.3728671,120.423316 C14.0580273,119.760569 14.5415345,118.923807 14.7692308,118.006758 Z" id="Path"></path>
                            </g>
                        </g>
                    </g>
                </svg>
                </Box>
              <Box as="span" ml="25px" display={["none", "none", "inline-block"]} verticalAlign="middle" className="text-sm">Twitter</Box>
              </Link>
            </Box>
            <Box
              gridAutoRows="1fr"
              display="block"
              pt={[0, 0, "19px"]}
              alignItems="center"
              pr={[0, 0, "5px"]}
              mr={[1, 1, 0]}
              h={["34px", "34px", "auto"]}>
              <Link
                href={`https://instagram.com/${instagram}`}
                aria-label="Instagram"
                leftIcon="at-sign"
                variant="outline"
                outline="none"
                opacity="1"
                display={"inline-flex"}
                alignItems="center"
                position="relative"
              >
              <Box position={["static", "static", "absolute"]} w={["34px", "34px", "17px"]} h={["34px", "34px", "17px"]} p={["5px", "5px", 0]} display="inline-block" verticalAlign="middle">
                <svg viewBox="0 0 17 17">
                  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                      <g id="Menu-/-Footer" transform="translate(-377.000000, -779.000000)" stroke="#242424" strokeWidth="1.5">
                          <g id="Group-45" transform="translate(378.000000, 624.000000)">
                              <g id="Group-43" transform="translate(0.000000, 156.000000)">
                                  <g id="Group-28">
                                      <rect id="Rectangle" x="-1.4033219e-13" y="-1.4033219e-13" width="14.7692308" height="14.7692308" rx="3.69230769"></rect>
                                      <path d="M10.3384615,6.91938462 C10.5253827,8.17991749 9.88284985,9.41822365 8.74458574,9.99114376 C7.60632163,10.5640639 6.22895894,10.3424276 5.32788108,9.44134969 C4.42680321,8.54027183 4.20516689,7.16290914 4.77808701,6.02464503 C5.35100712,4.88638092 6.58931328,4.24384805 7.84984615,4.43076923 C9.13696746,4.62163315 10.1475976,5.63226331 10.3384615,6.91938462 Z" id="Path"></path>
                                  </g>
                                  <line x1="11.4461538" y1="3.32307692" x2="11.4535385" y2="3.32307692" id="Path"></line>
                              </g>
                          </g>
                      </g>
                  </g>
                </svg>
              </Box>
              <Box as="span" ml="25px" display={["none", "none", "inline-block"]} verticalAlign="middle" className="text-sm">Instagram</Box>
              </Link>
            </Box>
            <Box
              gridAutoRows="1fr"
              display="block"
              pt={["0px", "0px", "19px"]}
              alignItems="center"
              pr={[0, 0, "5px"]}
              h={["34px", "34px", "auto"]}>
              <Link
                href={`https://linkedin.com/company/${linkedin}`}
                aria-label="LinkedIn"
                leftIcon="at-sign"
                variant="outline"
                outline="none"
                opacity="1"
                display={"inline-flex"}
                alignItems="center"
                position="relative"
              >
              <Box position={["static", "static", "absolute"]} w={["34px", "34px", "17px"]} h={["34px", "34px", "17px"]} p={["5px", "5px", 0]} display="inline-block" verticalAlign="middle">
                <svg viewBox="0 0 17 17">
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                        <g id="Menu-/-Footer" transform="translate(-377.000000, -818.000000)" stroke="#242424" strokeWidth="1.5">
                            <g id="Group-45" transform="translate(378.000000, 624.000000)">
                                <g id="Group-41" transform="translate(0.000000, 195.000000)">
                                    <path d="M10.8825911,4.66396761 C13.4584293,4.66396761 15.5465587,6.75209704 15.5465587,9.32793522 L15.5465587,14.7692308 L12.437247,14.7692308 L12.437247,9.32793522 C12.437247,8.46932249 11.7412038,7.77327935 10.8825911,7.77327935 C10.0239784,7.77327935 9.32793522,8.46932249 9.32793522,9.32793522 L9.32793522,14.7692308 L6.21862348,14.7692308 L6.21862348,9.32793522 C6.21862348,6.75209704 8.30675291,4.66396761 10.8825911,4.66396761 Z" id="Path"></path>
                                    <rect id="Rectangle" x="1.14130927e-13" y="5.44129555" width="3.10931174" height="9.32793522"></rect>
                                    <circle id="Oval" cx="1.55465587" cy="1.55465587" r="1.55465587"></circle>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
              </Box>
              <Box as="span" ml="25px" display={["none", "none", "inline-block"]} verticalAlign="middle" className="text-sm">LinkedIn</Box>
              </Link>
            </Box>
          </Box>
          <Grid autoRows="minmax(0, max-content)" templateColumns="repeat(1, 1fr)" spacing={2} color="footerText">
            <Box
                gridAutoRows="1fr"
                position="relative"
                height="33px"
                pt={[0, "7.5px", "7.5px"]}
                pr={[0, "5px", "5px"]}>
                  <div className={footerHeading}>Resources</div>
              </Box>
            <Box
                gridAutoRows="1fr"
                pt={[0, "15px", "30px"]}
                pr={[0, 0, "5px"]}>
                <Link
                  href={`${companyUrl}/showcase`}
                  aria-label="howcase">
                  <Box as="span" ml="5px" className="text-sm">Showcase</Box>
                </Link>
              </Box>
            <Box
                gridAutoRows="1fr"
                pt="19px"
                pr={[0, 0, "5px"]}>
                <Link
                  href={`${companyUrl}/video-commerce`}
                  aria-label="Video Commerce">
                  <Box as="span" ml="5px" className="text-sm">Video Commerce</Box>
                </Link>
              </Box>
            <Box
                gridAutoRows="1fr"
                pt="19px"
                pr={[0, 0, "5px"]}>
                <Link
                  href={`https://stories.maker.co/`}
                  aria-label="Terms">
                  <Box as="span" ml="5px" className="text-sm">Blog</Box>
                </Link>
              </Box>
            <Box
                gridAutoRows="1fr"
                pt="19px"
                pr={[0, 0, "5px"]}>
                <Link
                  href={`https://support.maker.me/`}
                  aria-label="Privacy">
                  <Box as="span" ml="5px" className="text-sm">Support</Box>
                </Link>
              </Box>
            <Box
                gridAutoRows="1fr"
                pt="19px"
                pr={[0, 0, "5px"]}>
                <Link
                  href={`https://status.maker.co/`}
                  aria-label="Privacy">
                  <Box as="span" ml="5px" className="text-sm">Status</Box>
                </Link>
              </Box>
            </Grid>
          <Grid autoRows="minmax(0, max-content)" templateColumns="repeat(1, 1fr)" spacing={2} color="footerText">
            <Box
              gridAutoRows="1fr"
              position="relative"
              height="33px"
              pt={[0, "7.5px", "7.5px"]}
              pr={[0, 0, "5px"]}>
                <div className={footerHeading}>Case Studies</div>
            </Box>
            {
                customers.map((item, i) => {
                  return (
                    <Box
                      gridAutoRows="1fr"
                      pt={i == 0 ? [0, "15px", "30px"]:"19px"}
                      key={i}
                      pr={[0, "5px", "5px"]}>
                      <Link
                        href={item.url}
                        aria-label={item.label}>
                        <Box as="span" ml="5px" className="text-sm">{item.label}</Box>
                      </Link>
                    </Box>);
                })
            }
          </Grid>
          <Grid autoRows="minmax(0, max-content)" templateColumns="repeat(1, 1fr)" spacing={2} color="footerText">
            <Box
              gridAutoRows="1fr"
              position="relative"
              height="33px"
              pt={[0, "7.5px", "7.5px"]}
              pr={[0, 0, "5px"]}>
                <div className={footerHeading}>Company</div>
            </Box>
            <Box
              gridAutoRows="1fr"
              pt={[0, "15px", "30px"]}
              pr={[0, "5px", "5px"]}>
              <Link
                href={`${companyUrl}/privacy-policy`}
                aria-label="Privacy">
                <Box as="span" ml="5px" className="text-sm">Privacy</Box>
              </Link>
            </Box>
            <Box
              gridAutoRows="1fr"
              pt="19px"
              pr={[0, "5px", "5px"]}>
              <Link
                href={`${companyUrl}/terms`}
                aria-label="Terms">
                <Box as="span" ml="5px" className="text-sm">Terms</Box>
              </Link>
            </Box>
            <VisuallyHidden>
              <Box
                gridAutoRows="1fr"
                pt="19px"
                pr={[0, "5px", "5px"]}>
                <Link
                  href={`/`}
                  aria-label="About Us">
                  <Box as="span" ml="5px" className="text-sm">About Us</Box>
                </Link>
              </Box>
              <Box
                gridAutoRows="1fr"
                pt="19px"
                pr={[0, "5px", "5px"]}>
                <Link
                  href={`/`}
                  aria-label="Career">
                  <Box as="span" ml="5px" className="text-sm">Career</Box>
                </Link>
              </Box>
            </VisuallyHidden>
            </Grid>
        </Box>
        <Box w="100%" textAlign="center">
          <Box
            pb={3} pt={10}>
            <Box color="footerText" className="text-sm" mb={0}>
              Copyright ©
              {' '}
              {copyrightYear}
              {' '}
              <Link
                href={companyUrl}
                underline={true}>{author}</Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;