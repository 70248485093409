import React, { useState } from "react"
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';
import SearchResultItem from "./SearchResultItem";
import {
  Icon,
  Box
} from '@chakra-ui/core';
import SearchIcon from '../../../images/ic-search.svg';

const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_API_KEY);

const Search = () => {
  const [searchValue, setSearchValue] = useState("")
  const [isSearchOpen, setSearchOpen] = useState(false)

  return (
  <div className="maker-SearchBox" open={!!isSearchOpen ? "true":null} value={!!searchValue ? searchValue:null}>
    <InstantSearch stalledSearchDelay={0} searchClient={searchClient} indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}>
      <SearchBox
      defaultRefinement={searchValue}
      onChange={(e) => {
        setSearchValue(e.target.value);
      }}
      submit={
        <span
        tabIndex="0"
        role="button"
        className="ais-IconButton"
        onClick={e => {
          setSearchOpen(!isSearchOpen);
        }}
        onKeyPress={() => {
          //do something
        }}>
          <img
            src={SearchIcon}
            alt={"Search"}
            width="18px"
            height="18px"
            color="navLink"
            style={{
              display: "inline-block"
            }}
          />
        </span>
      }
      reset={
      <span
        tabIndex="0"
        role="button"
        className="ais-IconButton"
        onClick={!isSearchOpen ? null:e => {
          setSearchOpen(!isSearchOpen);
          setSearchValue(null);
        }}
        onKeyPress={() => {
          //do something
        }}>
        <Icon
          name="close"
          size="12px"
          color="navLink"
          display={"inline-block"}
        />
      </span>} />

      <Box
        className="ais-ResultList shadow-2xl rounded overflow-hidden"
        position="absolute"
        top={["40px", "40px", "45px"]}
        w={["100vw", "100vw", "400px"]}
        maxH={["calc(100vh - 40px) !important", "calc(100vh - 40px) !important", "calc(100vh - 80px) !important"]}
        h={["100vh", "100vh", "auto"]}
        overflow="auto"
        my={0}
        py={"10px"}
        bg={["#fff", "#fff", "#fff"]}
        fontFamily="Work Sans"
        zIndex="998"
        boxSizing="border-box"
        transition="all .3s">
        <Hits hitComponent={SearchResultItem} />
      </Box>
    </InstantSearch>
  </div>);
};

export default Search;