import { graphql, useStaticQuery } from 'gatsby';

export default () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          titleTemplate
          description
          author
          twitter
          facebook
          instagram
          linkedin
          siteUrl
          companyUrl
          copyrightYear
          keywords
          googleAnalyticsId
        }
      }
    }
  `);
  return data.site.siteMetadata;
};
