import React from "react"

const MakerButton = ({link, text}) => {

    return (
        <a href={link} className="whitespace-no-wrap inline-flex items-center md:text-sm text-xs justify-center px-2 py-1 sm:px-4 sm:py-2 border border-transparent leading-6 font-base rounded-sm text-white bg-black focus:outline-none transition ease-in-out duration-150">
            {text}
        </a>
    );
}
export default MakerButton;