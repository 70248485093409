import { graphql, useStaticQuery } from 'gatsby';

export default () => {
  const menus = useStaticQuery(graphql`
  {
    wpgraphql {
      menus {
        nodes {
          menuItems {
            nodes {
              url
              label
            }
          }
        }
      }
    }
  }
  `);
  return menus
  .wpgraphql
  .menus
  .nodes[0]
  .menuItems
  .nodes;
};
